import React from 'react'
import './WhatULearn.sass'
import b1 from './assets/b1.svg'
import b2 from './assets/b2.svg'
import b3 from './assets/b3.svg'
import b4 from './assets/b4.svg'
import b5 from './assets/b5.svg'
import b6 from './assets/b6.svg'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'

function WhatULearn({ onCallbackRequest }) {
  const data = [
    {
      title: 'Максимально быстро',
      description: `Получите бесплатную юридическую консультацию в день обращения`,
      icon: b1,
    },
    {
      title: 'Ответственность',
      description: `Наш главный приоритет - положительный результат решения Вашего вопроса`,
      icon: b2,
    },
    {
      title: 'Индивидуальный подход',
      description: `Индивидуальный подход к каждому вопросу с максимальной вовлеченностью в его суть.`,
      icon: b3,
    },
    {
      title: 'Справедливые цены',
      description: `Мы не завышаем цены`,
      icon: b4,
    },
    {
      title: 'Любая сложность вопроса',
      description: `Наши квалифицированные специалисты способны решать юридические вопросы любой сложности`,
      icon: b5,
    },
    {
      title: 'Поддержка 24/7',
      description: `Готовы ответить на все ваши вопросы в мессенджерах, в любое время`,
      icon: b6,
    },
  ]
  return (
    <div className="EduBenefits">
      <div className="EduBenefits-Container DefaultContainer">
        <SectionTitle title={'Почему именно мы?'} />

        <div className="EduBenefits-TilesContainer">
          {data.map((d) => (
            <EduBenefitTile {...d} />
          ))}
        </div>
      </div>
    </div>
  )
}

function EduBenefitTile({ title, description, icon }) {
  return (
    <div className="EduBenefitTile">
      <div className="EduBenefitIcon">
        <img src={icon} alt={title} />
      </div>
      <h3 className="Title">{title}</h3>
      <p className="Body-Regular-1 Description">{description}</p>
    </div>
  )
}

export default WhatULearn
