import React, { useContext } from 'react'
import './Hero.sass'
import { withRouter } from 'react-router-dom'

import Icon from '../../ui/Icon/Icon'

import Button from '../../ui/Button/Button'
import heroBg from './assets/bg.jpg'
import { AuthContext } from '../../App'

function Hero() {
  const data = [
    { icon: 'thumbs-up', title: 'Первая консультация бесплатно' },
    { icon: 'credit-card', title: 'Онлайн оплата услуг' },
    { icon: 'user-clock', title: 'Быстро отвечаем на вопросы' },
    { icon: 'coins', title: 'Возможность рассрочки до 12 месяцев' },
  ]

  const { setShowContactUs } = useContext(AuthContext)

  return (
    <div id="home" className="Site-Hero">
      <div
        className="HeroBg"
        style={{ backgroundImage: `url(${heroBg})` }}
      ></div>
      <div className="DefaultContainer">
        <div className="Offer">
          <h1 className="Title">Юридическая консультация</h1>
          <p className="Description">
            Доверьтесь профессионалам с более 15 летним опытом работы
          </p>
          <div className="Benefits">
            {data.map((d) => (
              <Benefit {...d} />
            ))}
          </div>
          <div className="Buttons">
            <Button
              theme="primary"
              title="Заказать бесплатную консультацию"
              type="button"
              icon="phone"
              iconWeight="solid"
              size={48}
              onClick={() =>
                setShowContactUs({
                  show: true,
                  title: 'Заказать',
                  emailSubject: 'Запрос на консультацию',
                })
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Benefit({ icon, title }) {
  return (
    <div className="Benefit">
      <Icon name={icon} />
      <span>{title}</span>
    </div>
  )
}

export default withRouter(Hero)
