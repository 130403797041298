import React, { useContext } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import './About.sass'
import Button from '../../ui/Button/Button'
import bgImage from './about.jpg'
import { AuthContext } from '../../App'
import Icon from '../../ui/Icon/Icon'

function About() {
  const { contacts, setShowContactUs } = useContext(AuthContext)

  const benefits = [
    {
      title: 'Задайте Ваш вопрос',
      description: `Воспользуйтесь любой формой консультации на сайте`,
      step: 1,
    },
    {
      title: 'Бесплатная консультация',
      description: `Наши юристы предоставят бесплатную консультацию в рамках вашего вопроса и сообщат стоимость юридических услуг`,
      step: 2,
    },
    {
      title: 'Оформление договора',
      description: 'Оформите договор на оказание юридических услуг',
      step: 3,
    },
    {
      title: 'Решите Ваш вопрос',
      description: `Благодаря подробной юридической консультации наши специалисты приложат максимум усилий для решения вашего вопроса`,
      step: 4,
    },
  ]
  return (
    <section id="about" name="about" class="About">
      <div className="DefaultContainer">
        <SectionTitle title="Юрзащита онлайн" />
        <div class="About-Container">
          <div class="About-Text">
            <p>
              Наша компания работает напрямую с производителем, поэтому наши
              цены и качество вас приятно удивят. У нас вы можете заказать кухню
              на любой вкус, а сотрудники компании помогут вам с дизайном и в
              выборе материалов.
            </p>
            <p>
              <b>4 простых шага для решеия Вашего вопроса</b>
            </p>
            <div className="Benefits-Container">
              {benefits.map((b) => (
                <div className="BenefitTile">
                  <div className="Step">{b.step}</div>
                  <div className="Text">
                    <p className="Title">{b.title}</p>
                    <p className="Description">{b.description}</p>
                  </div>
                </div>
              ))}
            </div>

            <Button
              title="Заказать бесплатную консультацию"
              type="button"
              icon="phone"
              iconWeight="solid"
              onClick={() =>
                setShowContactUs({
                  show: true,
                  title: 'Заказать',
                  emailSubject: 'Заказать консультацию',
                })
              }
              // onClick={() => (window.location.href = `tel:${contacts.phone}`)}
            />
          </div>
          <div className="AboutImg">
            <img src={bgImage} alt="Gusto" />
          </div>
        </div>
      </div>
    </section>
  )
}

export default About
