import React from 'react'
import './Footer.sass'
import Logo from '../../ui/Logo/Logo'
import SocialLinks from '../Top/SocialLinks/SocialLinks'
import PhoneLinks from '../Top/PhoneLinks/PhoneLinks'
import EmailLink from '../Top/EmailLink/EmailLink'
import { NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'
import { NavHashLink } from 'react-router-hash-link'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import BusyHours from '../Top/BusyHours/BusyHours'
import AddressLink from '../Top/AddressLink/AddressLink'

function Footer() {
  return (
    <footer id="contacts" className="Footer">
      <div className="Main DefaultContainer">
        <Logo />
        <div className="ContactBlock">
          <BusyHours />
          <PhoneLinks />
          <EmailLink />
          <AddressLink />
          {/* <SocialLinks /> */}
        </div>
      </div>

      <div className="Copyrights DefaultContainer">
        © 2008-2024 Все права защищены.{' '}
        <a href="/pdf/policy.pdf" target="blank">
          Политика конфиденциальности и обработки персональных данных
        </a>
      </div>
    </footer>
  )
}

export default Footer
