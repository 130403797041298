import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './EmailLink.sass'
import { useContext } from 'react'
import { AuthContext } from '../../../App'

function EmailLink() {
  return (
    <div className="EmailLink">
      <a href={`mailto:gustokitchens77@gmail.com`} className="EmailIcon">
        <Icon name="envelope" weight="solid" />
      </a>
      <div className="Content">
        <a href={`mailto:md51online@yandex.ru`} className="Email">
          md51online@yandex.ru
        </a>
        <span className="Description">Напишите нам</span>
      </div>
    </div>
  )
}

export default EmailLink
