import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import Button from '../../ui/Button/Button'
import ContactUsPopUp from '../ContactUs/ContactUsPopUp'
import './Top.sass'
import { NavLink } from 'react-router-dom'
import SocialLinks from './SocialLinks/SocialLinks'
import PhoneLinks from './PhoneLinks/PhoneLinks'
import EmailLink from './EmailLink/EmailLink'
import BusyHours from './BusyHours/BusyHours'

function Top() {
  const { showContactUs, setShowContactUs } = useContext(AuthContext)

  return (
    <div className="Top">
      {showContactUs.show && <ContactUsPopUp />}
      <div className="Top-Container DefaultContainer">
        <span></span>
        {/* <SocialLinks /> */}
        <div className="Top-Contacts-Container">
          <BusyHours />
          <PhoneLinks />
          <EmailLink />
        </div>
      </div>
    </div>
  )
}

export default Top
