import React from 'react'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import { useState } from 'react'
import { createForm } from '../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import pattern from './assets/pattern.svg'
import { functions } from '../../config/firebase'
import FormRender from '../../utils/newforms/render/FormRender'
import Button from '../../ui/Button/Button'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import './RequestConsultation.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'

function RequestConsultation() {
  const { city } = useContext(AuthContext)
  const [form, setForm] = useState(
    createForm({
      formPattern: new ConsultForm(),
    })
  )
  const [isLoading, setIsLoading] = useState(false)
  const [showErrors, setShowErrors] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = async (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      return setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Будь ласка, заповніть усі поля форми',
        closeAfter: 5000,
      })
    }
    setIsLoading(true)

    try {
      window.ym(86916243, 'reachGoal', 'form')
    } catch (e) {
      console.log(e)
    }

    const values = getFormValues({ form })

    const func = functions.httpsCallable('emailSender')
    const text = `
      Ім'я клієнта: ${values.userName}<br/>
      Телефон: ${values.phone.value}<br/>
    `

    // sendSms({ message: text })

    func({
      subject: 'Запрос на консультацию',
      to: 'md51online@yandex.ru',
      html: text,
    }).then(({ data }) => {
      if (data === 'success') {
        setStatusMessage({
          show: true,
          type: 'success',
          message:
            'Мы получили Ваш запрос. Пожалуйста, ожидайте связи с нашим оператором',
          closeAfter: 5000,
        })
        setForm(createForm({ formPattern: new ConsultForm() }))
        return setIsLoading(false)
      }
    })
  }

  return (
    <div id="consult" name="consult" className="RequestConsultation">
      <div
        className="RequestConsultation-Container DefaultContainer Pattern"
        style={{ backgroundImage: `url(${pattern})` }}
      >
        <SectionTitle title="Получить бесплатную консультацию" />
        <p>
          Наши специалисты с радостью ответят на все интересующие Вас вопросы
        </p>
        {statusMessage.show && (
          <StatusMessage
            className="Site-StatusMessage"
            type={statusMessage.type}
            message={statusMessage.message}
          />
        )}
        <div className="RequestConsultation-Form">
          <FormRender
            sections={[{ fields: ['userName', 'phone'] }]}
            form={form}
            setForm={setForm}
            errors={showErrors}
          />
          <Button
            theme="primary"
            icon="phone"
            iconWeight="solid"
            title="Заказать бесплатную консультацию"
            isLoading={isLoading}
            onClick={onSubmit}
          />
        </div>
        <p className="Caption-Medium">
          Отправляя эту форму вы соглашаетесь с нашей{' '}
          <a href="/pdf/policy.pdf" target="blank">
            политикой конфиденциальности
          </a>
        </p>
      </div>
    </div>
  )
}

class ConsultForm {
  constructor() {
    this.userName = {
      field: {
        fieldId: 'userName',
        fieldType: 'input',
        inputType: 'text',
        placeholder: 'Ваше имя',
        required: true,
      },
      render: getFieldRenderObject(),
    }
    this.phone = {
      field: {
        fieldId: 'phone',
        fieldType: 'phone',
        inputType: 'text',
        placeholder: 'Телефон',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default RequestConsultation
