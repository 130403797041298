import React, { useEffect, useState } from 'react'
import Icon from '../../ui/Icon/Icon'
import './FeedsCarousel.sass'
import FeedTile from './FeedTile/FeedTile'
import getCollection from '../../utils/db/getCollection'
import { find, isEmpty, orderBy, toNumber } from 'lodash'
import Carousel from '../../ui/Carousel/Carousel'
import PopUp from '../../ui/PopUp/PopUp'
import Button from '../../ui/Button/Button'
import parse from 'html-react-parser'
import promiseAllValues from '../../utils/promiseAllValues'
import getDoc from '../../utils/db/getDoc'
import { useContext } from 'react'
import { AuthContext } from '../../App'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'

function FeedsCarousel({ className }) {
  const [feeds, setFeeds] = useState([
    {
      feedId: '1',
      author: 'Владимир Никитин',
      created: '24 марта 2024',
      text: `
      С помощью сайта удалось более детально разобраться со своей проблемой (необоснованное завышение цены тарифа за отопление).
      `,
    },
    {
      feedId: '2',
      author: 'Людмила Лапова',
      created: '02 апреля 2024',
      text: `
      Спасибо за оперативное решение квартирного вопроса. Благодаря вам быстро собрала все документы и въехала в новое жилье. Уже порекомендовала ваш сайт друзьям!
      `,
    },
    {
      feedId: '3',
      author: 'Олег Божок',
      created: '05 апреля 2024',
      text: `
      Спасибо мне очень помогли юристы с вашего сайта. процветания!!!
      `,
    },
    {
      feedId: '4',
      author: 'Марина Каштанова',
      created: '11 апреля 2024',
      text: `
      Хочу сказать, что юристы Вашей команды оказали действительно очень большую помощь в решении моей проблемы. P.S. Вы делаете очень важную для людей работу!
      `,
    },
  ])
  const [showPopUp, setShowPopUp] = useState(false)
  const { setShowContactUs } = useContext(AuthContext)

  // useEffect(() => {
  //   getCollection({
  //     path: 'accounts/mcun/feeds',
  //     docIdName: 'feedId',
  //   }).then((result) => {
  //     setFeeds(result)
  //   })
  // }, [])

  return (
    <div id="feeds" name="feeds" className={`FeedsCarousel ${className}`}>
      <div className="FeedsCarousel-Container DefaultContainer">
        <SectionTitle title="Отзывы" />
        <div className="RatingButtons">
          <a href="" className="RatingButton Google">
            <Icon name="google" weight="brand" />
            <span className="Caption-Medium">Рейтинг:</span>
            <span className="Caption-Regular">5.0</span>
          </a>
          <a href="" target="blank" className="RatingButton Yandex">
            <Icon name="yandex" weight="brand" />
            <span className="Caption-Medium">Рейтинг:</span>
            <span className="Caption-Regular">4.9</span>
          </a>
        </div>
        {!isEmpty(showPopUp) && (
          <PopUp
            show={showPopUp}
            title={showPopUp.author}
            close={() => setShowPopUp(false)}
          >
            <div className="DescriptionContent">{parse(showPopUp.text)}</div>
            <Button
              className="CloseFeedDescription"
              theme="primary-white"
              title="Закрыть"
              type="button"
              onClick={() => setShowPopUp(false)}
            />
          </PopUp>
        )}
        <div className="FeedsCarousel-Feeds">
          <div className="FeedContainer">
            {!isEmpty(feeds) && (
              <Carousel
                element="Feeds-Carousel"
                options={{
                  type: 'carousel',
                  gap: 24,
                  perView: 4,
                  hoverpause: true,
                  // autoplay: 5000,
                  // useDots: true,
                  useArrows: true,
                  breakpoints: {
                    1200: { perView: 3 },
                    992: { perView: 2 },
                    576: { perView: 1 },
                  },
                }}
              >
                {feeds.map((f) => (
                  <FeedTile key={f.feedId} {...f} setShowPopUp={setShowPopUp} />
                ))}
              </Carousel>
            )}
            {/* <div className="LeaveFeedBlock">
              <h3>Уже были нашим клиентом?</h3>
              <p className="Body-Regular-1">
                Нам важно знать, что Вы думаете о качестве наших услуг. Будем
                рады Вашему отзыву
              </p>
              <Button
                theme="primary"
                title="Оставить отзыв"
                type="button"
                icon="envelope"
                iconWeight="solid"
                onClick={() =>
                  setShowContactUs({
                    show: true,
                    title: 'Оставить отзыв',
                    emailSubject: '💬 Отзыв с сайта',
                  })
                }
              />
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default FeedsCarousel
