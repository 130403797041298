import React from 'react'
import './PortfolioCarousel.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import test from './assets/1.jpg'
import Carousel from '../../ui/Carousel/Carousel'
import Button from '../../ui/Button/Button'

function PortfolioCarousel() {
  return (
    <div id="portfolio" name="portfolio" className="Portfolio">
      <div className="DefaultContainer">
        <SectionTitle title="Портфолио работ" />
        <Carousel
          element="Portfolio-Carousel"
          options={{
            type: 'carousel',
            gap: 24,
            perView: 4,
            hoverpause: true,
            // autoplay: 5000,
            // useDots: true,
            useArrows: true,
            breakpoints: {
              1200: { perView: 3 },
              992: { perView: 2 },
              576: { perView: 1 },
            },
          }}
        >
          <PortfolioTile />
          <PortfolioTile />
          <PortfolioTile />
        </Carousel>
      </div>
    </div>
  )
}

function PortfolioTile({ title = 'Кухня Агота' }) {
  return (
    <div className="PortfolioTile">
      <img src={test} alt={title} />
      <div className="Content">
        <p className="Title">{title}</p>
        <ParamData title="Стиль" value="модерн" />
        <ParamData title="Форма" value="угловая" />
        <ParamData title="Цвет" value="зеленый" />
        <div className="PriceBlock">
          <p className="Label">Цена от</p>
          <p className="PriceValue">40800 ₽</p>
        </div>
        <Button
          title="Заказать расчет"
          type="button"
          icon="phone"
          iconWeight="solid"
          // onClick={() =>
          //   setShowContactUs({
          //     show: true,
          //     title: 'PЗаказать',
          //     emailSubject: 'Запит на консультацію',
          //   })
          // }
        />
      </div>
    </div>
  )
}

function ParamData({ title, value }) {
  return (
    <li className="ParamData">
      <span className="ParamTitle">{title}</span>
      <span className="ParamDevider">&nbsp;</span>
      <span className="ParamValue">{value}</span>
    </li>
  )
}

export default PortfolioCarousel
