import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './BusyHours.sass'

function BusyHours() {
  // const { contacts } = useContext(AuthContext)
  return (
    <div className="BusyHours">
      <Icon name="clock" weight="solid" />
      <div className="Content">
        <span className="Text">10:00 - 18:00</span>
        <span className="Description">с Понедельника по Пятницу</span>
      </div>
      {/* <a href="viber://chat?number=%2B380988833880" target="blank">
        <Icon name="viber" weight="brand" />
      </a>
      <a href="https://t.me/+380988833880" target="blank">
        <Icon name="telegram" weight="brand" />
      </a>
      <a
        href="https://api.whatsapp.com/send/?phone=380988833880"
        target="blank"
      >
        <Icon name="whatsapp" weight="brand" />
      </a> */}
    </div>
  )
}

export default BusyHours
