import React from 'react'
import Icon from '../../../ui/Icon/Icon'
import './PhoneLinks.sass'
import { useContext } from 'react'
import { AuthContext } from '../../../App'

function PhoneLinks() {
  // const { contacts } = useContext(AuthContext)
  return (
    <div className="PhoneLinks">
      <a href={`tel:88002507090`} className="PhoneIcon">
        <Icon name="phone" weight="solid" />
      </a>
      <div className="Content">
        <a href={`tel:79210303332`} className="PhoneNumber ">
          8 (800) 250-70-90
        </a>
        <span className="Description">Звоните нам</span>
      </div>
    </div>
  )
}

export default PhoneLinks
