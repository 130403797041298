import React, { useState } from 'react'
import './FeedTile.sass'
import { formatDateAsDayMonthNameYear } from '../../../utils/date/dateFormat'
import parse from 'html-react-parser'
import Button from '../../../ui/Button/Button'
import PopUp from '../../../ui/PopUp/PopUp'

function FeedTile({ key, author, created, text, setShowPopUp }) {
  console.log('🚀 ~ file: FeedTile.jsx:9 ~ FeedTile ~ created:', created)
  return (
    <>
      <div className="FeedTile">
        <div className="Feed-Author">
          <span className="UserName">{author}</span>
          <span className="Date Caption-Medium">{created}</span>
        </div>
        <div className="Feed-Text">{parse(text)}</div>
        <Button
          theme="internal-link"
          title="Подробнее"
          icon="long-arrow-right"
          iconPosition="right"
          type="button"
          size="24"
          onClick={() =>
            setShowPopUp({
              author,
              text,
            })
          }
        />
      </div>
    </>
  )
}

export default FeedTile
