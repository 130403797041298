import React from 'react'
import './AddressLink.sass'
import Icon from '../../../ui/Icon/Icon'

function AddressLink() {
  return (
    <div className="AddressLinks">
      <Icon name="map-marker-alt" weight="solid" />
      <div className="Content">
        <span className="Text">г. Мурманск, пр. Ленина 47 офис 309</span>
        <span className="Description">наш офис</span>
      </div>
    </div>
  )
}

export default AddressLink
