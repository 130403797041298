import React, { useState } from 'react'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'

import i1 from './assets/1.png'
import i2 from './assets/2.png'
import i3 from './assets/3.png'
import i4 from './assets/4.png'
import i5 from './assets/5.png'
import i6 from './assets/6.png'
import i7 from './assets/7.png'

import './Style.sass'
import { find } from 'lodash'
import InfoLine from '../../ui/InfoLine/InfoLine'

function Style() {
  const [activeTab, setActiveTab] = useState(1)
  const data = [
    {
      id: 1,
      bg: i1,
      title: 'Избавление от долгов',
      content: [
        'Уменьшение ежемесячного платежа',
        'Оптимизация задолженности и уменьшение ее до 60% от общей суммы долга по договорам',
        'Анализ и оспаривание законности заключенного кредитного договора/займа',
        'Предотвращение взыскания ссудодателем на предмет залога',
        'Разработка и согласование с Банком/МФО щадящей и выгодной для клиента программы погашения задолженности',
        'Инициация перерасчета долга по кредиту/займу с целью его уменьшения',
        'Защита имущества должника',
        'Защита от коллекторов',
        'Уменьшение размера процентов, штрафов, пеней и неустоек',
        'Оптимизация структуры долга',
        'Возврат душевного спокойствия и равновесия',
      ],
    },
    {
      id: 2,
      bg: i2,
      title: 'Жилищне вопросы',
      content: [
        'Возмещение ущерба причиненного в результате залития квартиры',
        'Жилищная субсидия',
        'Порядок пользования',
        'Раздел лицевых счетов',
        'Социальный найм',
        'Споры ЖКУ',
        'Приватизация',
        'Долги по коммунальным услугам',
        'Ипотека и др.',
      ],
    },
    {
      id: 3,
      bg: i3,
      title: 'Семейные вопросы',
      content: [
        'Порядок общения с ребенком',
        'Алименты',
        'Установление отцовства',
        'Место жительства ребенка',
        'Развод',
        'Лишение / восстановление родительских прав',
        'Раздел имущества',
        'Признание членом семьи и др.',
      ],
    },
    {
      id: 4,
      bg: i4,
      title: 'Рабочие вопросы',
      content: [
        'Увольнение',
        'Оплата труда',
        'Переработка',
        'Дисциплинарные взыскания и др.',
      ],
    },
    {
      id: 5,
      bg: i5,
      title: 'Вопросы наследства',
      content: [
        'Признание права наследования',
        'Долги по наследству',
        'Оспаривание завещания',
        'Принятие наследства или отказ',
        'Раздел наследуемого имущества и др.',
      ],
    },
    {
      id: 6,
      bg: i6,
      title: 'Договора',
      content: ['Признание права наследования'],
    },
    {
      id: 7,
      bg: i7,
      title: 'Другие вопросы',
      content: [
        'Установление юридических фактов',
        'Защита прав потребителя',
        'Возмещение ущерба и др.',
      ],
    },
  ]

  const thisActiveTab = find(data, ['id', activeTab])

  return (
    <div className="Style" id="services" name="services">
      <div className="DefaultContainer">
        <SectionTitle title="Юридические услуги" />
        <div className="StyleTiles-Container">
          {data.map((d) => (
            <StyleTile
              key={d.title}
              {...d}
              onClick={() => setActiveTab(d.id)}
            />
          ))}
        </div>
        <div className="Info-Container">
          <h2>{thisActiveTab.title}</h2>
          <div className="Content">
            {thisActiveTab.content.map((c) => (
              <InfoLine icon="check" title={c} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

function StyleTile({ bg, title = 'Хай тек', onClick }) {
  return (
    <div
      onClick={onClick}
      className="StyleTile"
      style={{ backgroundImage: `url(${bg})` }}
    >
      <p className="Title">{title}</p>
    </div>
  )
}

export default Style
