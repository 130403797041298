import React from 'react'
import './InfoLine.sass'
import Icon from '../Icon/Icon'

function InfoLine({ icon, title, content }) {
  return (
    <p className="InfoLine">
      {icon && <Icon name={icon} />}
      {title && <span>{title}</span>}
      {content}
    </p>
  )
}

export default InfoLine
