import React, { useState } from 'react'
import { HashLink } from 'react-router-hash-link'
import Icon from '../../ui/Icon/Icon'
import scrollWithOffset from '../../ui/ScrollToTop/scrollWithOffset'
import './CookieNotification.sass'

function CookieNotification() {
  const [acceptedCookie, setAcceptedCookie] = useState(
    localStorage.getItem('acceptedCookies')
  )

  const onCookiesAccept = () => {
    localStorage.setItem('acceptedCookies', true)
    setAcceptedCookie(true)
  }

  return !acceptedCookie ? (
    <div className="Site-CookieNotification">
      <div className="CookieNotification-Container">
        <div className="CookieNotification-Button">
          <Icon name="times" onClick={onCookiesAccept} />
        </div>
        <div className="CookieNotification-Text">
          Этот сайт использует файлы cookie и похожие технологии, что бы
          гарантировать максимальное удобство для пользователя. При
          использование данного сайта вы подтверждаете свое согласие на
          использование файлов cookie в соответствии с данным сообщением и нашей
          политикой конфиденциальности. Если вы не согласны с использованием
          файлов cookie, то вы должны настроить ваш браузер соответствующим
          образом либо покинуть этот сайт.
          {/* Цей сайт використовує файли cookie і схожі технології, щоб гарантувати
          максимальну зручність користувачам. При використанні даного сайту, ви
          підтверджуєте свою згоду на використання файлів cookie відповідно до
          цього повідомленням щодо даного типу файлів Якщо ви не згодні з тим,
          щоб ми використовували даний тип файлів, то ви повинні відповідним
          чином встановити налаштування вашого браузера або не використовувати
          цей сайт. */}
        </div>
      </div>
    </div>
  ) : null
}

export default CookieNotification
