import React from 'react'
import './SectionTitle.sass'
import bg from './decorator.svg'

function SectionTitle({ title, theme = null }) {
  return (
    <h5 className={`SectionTitle ${theme ? 'SectionTitle_theme_white' : ''}`}>
      <span className="Decorator" style={{ backgroundImage: `url(${bg})` }} />
      {title}
    </h5>
  )
}

export default SectionTitle
