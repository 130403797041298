import React, { useState } from 'react'
import './Oplata.sass'
import SectionTitle from '../../ui/SectionTitle/SectionTitle'
import getFieldRenderObject from '../../utils/newforms/render/getFieldRenderObject'
import { createForm } from '../../utils/newforms/createForm'
import StatusMessage, {
  useStatusMessage,
} from '../../ui/StatusMessage/StatusMessage'
import isFormValid from '../../utils/newforms/validation/isFormValid'
import getFormValues from '../../utils/newforms/getFormValues'
import FormRender from '../../utils/newforms/render/FormRender'
import Button from '../../ui/Button/Button'

function Oplata() {
  const [form, setForm] = useState(
    createForm({ formPattern: new OplataForm() })
  )
  const [showErrors, setShowErrors] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [statusMessage, setStatusMessage] = useStatusMessage()

  const onSubmit = (e) => {
    e.preventDefault()
    if (!isFormValid({ form })) {
      setStatusMessage({
        show: true,
        type: 'fail',
        message: 'Пожалуйста, заполните все поля формы',
        closeAfter: 5000,
      })
      return setShowErrors(true)
    }
    setShowErrors(false)
    setIsLoading(true)

    const values = getFormValues({ form })

    const testForm = new FormData()
    testForm.append('terminalkey', '1669208646189')

    // const data = {
    //   event: {
    //     target: {
    //       elements: {
    //         terminalkey: '1669208646189',
    //         frame: 'true',
    //         language: 'ru',
    //         amount: values.sum,
    //         order: Date.now(),
    //         description: 'Оплата услуг',
    //         name: 'Голосова Галина Васильевна',
    //         email: 'ggv6192@yandex.ru',
    //         phone: '79212806192',
    //       },
    //     },
    //   },
    // }

    // window.pay(data)

    // const func = functions.httpsCallable('emailSender')
    // func({
    //   subject,
    //   to: 'md51online@yandex.ru',
    //   html: `
    //   Имя: ${values.name}<br/>
    //   E-mail: ${values.email}<br/>
    //   Номер телефона: ${values.phone.value}<br/>
    //   Комментарий: ${values.comment}<br/>
    //   ${additionalContent || ''}`,
    // }).then(({ data }) => {
    //   if (data === 'success') {
    //     setStatusMessage({
    //       show: true,
    //       type: 'success',
    //       message:
    //         'Мы получили Ваш запрос. Пожалуйста, ожидайте связи с нашим оператором',
    //       closeAfter: 5000,
    //     })
    //     setForm(createForm({ formPattern: new ContactForm() }))
    //     return setIsLoading(false)
    //   }
    // })
  }

  return (
    <div className="Oplata">
      <div className="DefaultContainer">
        <SectionTitle title="Оплата услуг" />
        <div className="Oplata-Container">
          <iframe
            width="100%"
            height="800"
            src={`https://md51-online.ru/oplata.php?v=${Date.now()}`}
            style={{ border: 0 }}
          ></iframe>
          {/* <form onSubmit={onSubmit} className="OplataForm">
            <div className="Form-Fields">
              {statusMessage.show && (
                <StatusMessage
                  className="Site-StatusMessage"
                  type={statusMessage.type}
                  message={statusMessage.message}
                />
              )}
              <FormRender
                form={form}
                setForm={setForm}
                sections={[{ fields: ['sum'] }]}
                errors={showErrors}
              />
            </div>
            <div className="ContactUsForm-Buttons">
              <Button
                title={'Отправить'}
                theme="solid"
                fill="black"
                iconWeight="solid"
                size={48}
                onClick={onSubmit}
                isLoading={isLoading}
              />
            </div>
          </form> */}
        </div>
      </div>
    </div>
  )
}

class OplataForm {
  constructor() {
    this.sum = {
      field: {
        fieldId: 'sum',
        fieldType: 'input',
        inputType: 'text',
        label: 'Сумма',
        required: true,
      },
      render: getFieldRenderObject(),
    }
  }
}

export default Oplata
