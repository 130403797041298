import React, { useEffect, createContext, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import Home from './pages/Home/Home'
import SitePage from './pages/SitePage/SitePage'
import { firebase } from './config/firebase'
import ScrollToTop from './ui/ScrollToTop/ScrollToTop'
import CookieNotification from './components/CookieNotification/CookieNotification'
// import TourPage from './components/TourPage/TourPage'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import getDoc from './utils/db/getDoc'
import Services from './pages/Services/Services'
import TextPage from './pages/TextPage/TextPage'
import Scientific from './pages/Scientific/Scientific'
import { isEmpty } from 'lodash'
import Contacts from './pages/Contacts/Contacts'
import Doctors from './components/Doctors/Doctors'
import DoctorsPage from './pages/DoctorsPage/DoctorsPage'
import Oplata from './pages/Oplata/Oplata'
import Success from './pages/Success/Success'

export const AuthContext = createContext(null)

const AuthProvider = ({ children }) => {
  // const [user, setUser] = useState(null)
  const [showContactUs, setShowContactUs] = useState({
    show: false,
    title: '',
    emailSubject: '',
  })
  // const [lang, setLang] = useState(localStorage.getItem('lang') || 'ru')
  const [contacts, setContacts] = useState(null)

  useEffect(() => {
    getDoc({ path: 'pages', docId: 'contacts' }).then((data) =>
      setContacts(data)
    )
  }, [])

  // useEffect(() => {
  //   const userStateListener = firebase.auth().onAuthStateChanged(setUser)
  //   return () => {
  //     userStateListener()
  //   }
  // }, [])

  return !isEmpty(contacts) ? (
    <AuthContext.Provider value={{ contacts, showContactUs, setShowContactUs }}>
      {children}
    </AuthContext.Provider>
  ) : null
}

function App() {
  return (
    <AuthProvider>
      <ScrollToTop />
      <Switch>
        <Route path="/success" exact component={Success} />
        <SitePage>
          <Route path="/" exact component={Home} />
          <Route path="/oplata" exact component={Oplata} />
          {/* <Route
          path="/laser-vision-correction"
          exact
          render={() => <TextPage docId={'laser'} />}
        />
        <Route
          path="/about-optics"
          exact
          render={() => <TextPage docId={'optics'} />}
        />
        <Route
          path="/scientific-activity"
          exact
          render={() => <Scientific title="Наукова діяльність" path="nauk" />}
        />
        <Route
          path="/mass-media"
          exact
          render={() => <Scientific title="ЗМІ" path="smi" />}
        />
        <Route path="/doctors" exact render={() => <DoctorsPage />} />
        <Route path="/contacts" exact render={() => <Contacts />} /> */}
          {/* <Route path="/project/:id" exact component={ProjectPage} />
        <Route
          path={['/manage-project', '/manage-project/:id']}
          exact
          component={ManageProjectPage}
        />
        <Route path="/admin" exact component={Auth} /> */}
        </SitePage>
      </Switch>

      <CookieNotification />
    </AuthProvider>
  )
}

export default App
