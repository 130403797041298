import React from 'react'
import './Home.sass'
import Hero from '../../components/Hero/Hero'
import About from '../../components/About/About'
import Doctors from '../../components/Doctors/Doctors'
import RequestConsultation from '../../components/RequestConsultation/RequestConsultation'
import MainServices from '../../components/MainServices/MainServices'
import FeedsCarousel from '../../components/FeedsCarousel/FeedsCarousel'
import Partners from '../../components/Partners/Partners'
import WhatULearn from '../../components/WhatULearn/WhatULearn'
import PortfolioCarousel from '../../components/PortfolioCarousel/PortfolioCarousel'
import Style from '../../components/Style/Style'
import Calc from '../../components/Calc/Calc'

function Home() {
  return (
    <div className="Home">
      <Hero />
      <Style />
      <RequestConsultation />
      <About />
      <WhatULearn />
      <FeedsCarousel />
    </div>
  )
}

export default Home
